import { IdsIcon, IdsText } from '@emergn-infinity/ids-react';

const EmptySearch = () => {
  return (
    <div className="EmptySearch">
      <IdsIcon icon="ui-search-search_bar" size="xl" />
      <IdsText alignment="center">Your search results will appear here</IdsText>
    </div>
  );
};

export default EmptySearch;
