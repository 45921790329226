/**
 * Sets a nested property on an object given a dot-separated key.
 *
 * @param {Record<string, any>} object - The object on which to set the property.
 * @param {string} key - The dot-separated key representing the property path.
 * @param {any} value - The value to set at the specified property path.
 * @returns {Record<string, any>} - The updated object with the nested property set.
 */

export const setNestedProperty = (object: Record<string, any>, key: string, value: any) => {
  const keys = key.split('.');
  let current = object;

  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i];

    if (i === keys.length - 1) {
      current[currentKey] = value;
    } else {
      current[currentKey] = current[currentKey] || {};
      current = current[currentKey];
    }
  }

  return object;
};

/**
 * Method that converts an array of strings to an object, where the key is the string with the first letter
 * of each word capitalized, the first letter of the string is lower case, and spaces are removed,
 * and the value is the original string.
 *
 * @param array
 */
export const convertArrayOfStringsToObject = (array: string[]): object => {
  const object = {};
  for (const element of array) {
    // don't proceed with empty strings
    if (!element) continue;
    // capitalize the first letter of each word of the string
    const capitalizedElement = element.replaceAll(/\w\S*/g, (w) => w.replaceAll(/^\w/g, (c) => c.toUpperCase()));
    // change the first char of the string to be lower case
    const firstCharLowerCase = capitalizedElement.charAt(0).toLowerCase() + capitalizedElement.slice(1);
    // remove spaces from the string
    const key = firstCharLowerCase.replaceAll(/\s/g, '');
    // set key and value in object
    object[`${key}`] = element;
  }

  return object;
};
