export enum AppSections {
  KANBAN_BOARD = 'board',
  EXPLORE = 'explore',
  ADMIN = 'admin',
  ADMIN_USERS = 'admin-users',
  ADMIN_B2C_USERS = 'admin-b2c-users',
  ACCOUNT = 'account',
  PERSONAL_INFORMATION = 'personal-information',
  SUBSCRIPTION_AND_BILLING = 'subscription-and-billing',
  PRIVACY = 'privacy',
  PASSWORD = 'password',
  CHECKLIST = 'checklist',
  ANALYTICS = 'analytics',
  INSTRUCTOR = 'instructor',
  CHATBOT = 'chatbot',
}
