import { IdsText } from '@emergn-infinity/ids-react';

import { ModelType } from '@/constants/explore';
import { Chapter, SearchItemType } from '@/interfaces';

export const getFilteredResults = (searchResults: object, selectedFilters: string[]) => {
  if (selectedFilters.length === 0) {
    return searchResults;
  }

  const filteredResults: any = {};
  for (const key of Object.keys(searchResults)) {
    if (selectedFilters.includes(key)) {
      filteredResults[key] = searchResults[key];
    }
  }

  return filteredResults;
};

export const limitObjectItems = (obj: any, numItems: number) => {
  const result: { [key: string]: any[] } = {};
  let count = 0;

  for (const key in obj) {
    if (count >= numItems) break;

    result[key] = [];
    for (const item of obj[key]) {
      if (count >= numItems) break;

      result[key].push(item);
      count++;
    }
  }

  return result;
};

const isMatchingSearchTerm = (text: string, searchTerm: string): boolean => {
  if (!text) return false;

  return text.toLowerCase().includes(searchTerm.toLowerCase());
};

export const getHighlightedText = (text: string, searchText: string, trimText = false) => {
  if (!searchText || !isMatchingSearchTerm(text, searchText)) {
    return <>{text}</>;
  }

  const termPosition = text.toLowerCase().indexOf(searchText.toLowerCase());

  // define a starting position for the text to be displayed on the modal
  // in this case is 50 characters before the term position or the beginning of the text
  const startPosition = termPosition - 50 < 0 ? 0 : termPosition - 50;
  const selectedText = trimText ? text.slice(startPosition) : text;

  const newTermPosition = selectedText.toLowerCase().indexOf(searchText.toLowerCase());

  return (
    <IdsText component="span">
      <>
        {selectedText.slice(0, newTermPosition)}
        <strong className="HighlightedText">
          {selectedText.slice(newTermPosition, newTermPosition + searchText.length)}
        </strong>
        {selectedText.slice(newTermPosition + searchText.length)}
      </>
    </IdsText>
  );
};

const findTermOnContent = (item: any, searchTerm: string) => {
  let selectedSentence = '';

  // check on what part of the content the term was found
  for (const contentItem of item.content) {
    if (isMatchingSearchTerm(contentItem.text, searchTerm)) {
      selectedSentence = contentItem.text;
    }
  }

  return selectedSentence;
};

const findTermOnChapter = (bookChapter: any, searchTerm: string) => {
  let selectedSentence = '';
  if (!isMatchingSearchTerm(bookChapter.title, searchTerm)) {
    if (isMatchingSearchTerm(bookChapter.description, searchTerm)) {
      selectedSentence = bookChapter.description;
    } else if (bookChapter.content) {
      selectedSentence = findTermOnContent(bookChapter, searchTerm);
    }
  }

  return selectedSentence;
};

/**
 * Find the matching sentence containing the search term within the item's content.
 * First checks the item title, then description/overview, then content sections.
 * For books, also searches within the first chapter's content.
 * @param item - The item to search for the matching sentence
 * @param searchTerm - The search term to find on the item
 * @param itemTitle - The title of the item
 * @param contentType - The type of the item
 * @param emptySearch - Whether the search is empty
 * @returns The matching sentence and the info from the chapter
 */
export const findMatchingSentence = (
  item: SearchItemType,
  searchTerm: string,
  itemTitle: string,
  contentType: ModelType,
  emptySearch: boolean,
) => {
  let infoFromChapter: Chapter | null = null;
  let selectedSentence = item.selectedSentence || '';
  // if the term was not found on the item's title
  if (!isMatchingSearchTerm(itemTitle, searchTerm) && !selectedSentence && !emptySearch) {
    // check if the term was found on item's description
    if (isMatchingSearchTerm(item.description || item.shortDescription || '', searchTerm)) {
      selectedSentence = item.description || item.shortDescription || '';
    } else if (isMatchingSearchTerm(item.overview || '', searchTerm)) {
      selectedSentence = item.overview || '';
    } else if (item.content) {
      // if the term is still not found, check if it was found on the item's content
      selectedSentence = findTermOnContent(item, searchTerm);
    } else if (contentType === ModelType.Book) {
      const bookChapter = item.chapters?.[0];
      // if the term is not found on the chapter's title, search its content
      if (bookChapter) {
        infoFromChapter = bookChapter;
        selectedSentence = findTermOnChapter(bookChapter, searchTerm);
      }
    }
  }

  return { selectedSentence, infoFromChapter };
};
