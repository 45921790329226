import { UserSubscription } from '../user';

export type B2cUsersType = {
  id: string;
  name: string;
  surname: string;
  email: string;
  jobRole: string;
  company: string;
  subscription: Pick<UserSubscription, 'customerId' | 'status' | 'type' | 'trialEnd'>;
  additionalInfo: {
    lastActiveAt: Date;
    interests: {
      items: string[];
      updatedAt: Date;
    };
    registeredAt: Date;
    isActive: boolean;
    registrationLink?: string;
    linkGeneratedAt?: Date;
  };
  certificationInterests: string[];
};

export enum B2cUsersTypeEnum {
  name = 'name',
  surname = 'surname',
  email = 'email',
  jobRole = 'jobRole',
  company = 'company',
  accountStatus = 'accountStatus',
  subscriptionStatus = 'subscriptionStatus',
  subscriptionType = 'subscriptionType',
  paymentHistory = 'paymentHistory',
  actions = 'actions',
  certificationInterests = 'certificationInterests',
}
