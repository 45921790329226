import { CmsColor } from '@/interfaces/general/cms-colors';

export const getColorFromCategory = (categoryColor: string | undefined) => {
  switch (categoryColor) {
    case CmsColor.Red: {
      return 'brand';
    }
    case CmsColor.Orange: {
      return 'brand-b';
    }
    case CmsColor.Blue: {
      return 'brand-c';
    }
    case CmsColor.Purple: {
      return 'brand-d';
    }
    default: {
      return 'neutral';
    }
  }
};
