// @ts-nocheck
export function enterFullscreen(ref: HTMLDivElement, setFullScreenMode?: (arg: bool) => void): void {
  if (setFullScreenMode) {
    setFullScreenMode(true);
  }
  if (ref.requestFullscreen) {
    ref.requestFullscreen();
  } else if (ref.webkitRequestFullscreen) {
    ref.webkitRequestFullscreen();
  } else if (ref.msRequestFullscreen) {
    ref.msRequestFullscreen();
  } else if (ref.mozRequestFullscreen) {
    ref.mozRequestFullscreen();
  }
}

export function exitFullscreen(setFullScreenMode?: (arg: bool) => void): void {
  if (setFullScreenMode) {
    setFullScreenMode(false);
  }
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozExitFullscreen) {
    document.mozExitFullscreen();
  }
}
