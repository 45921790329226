import { MODULE_PROGRESS_STATUS, Course, Pathway, UserCourseModule, UserPathwayModule } from '@/interfaces';
import { useAddCourseModuleMutation, useAddPathwayModuleMutation } from '@/redux/api/kanban-board-api-slice';
export const useModuleActions = (
  userCourseModules: UserCourseModule[],
  userPathwayModules: UserPathwayModule[],
  availableCourses: Course[],
  availablePathways: Pathway[],
) => {
  const [addCourseModule] = useAddCourseModuleMutation();
  const [addPathwayModule] = useAddPathwayModuleMutation();

  const isModuleOnBoard = (moduleId: string, parent: { courseId?: string; pathwayId?: string }) => {
    const moduleParent =
      'courseId' in parent
        ? availableCourses.find((course) => course.courseId === parent.courseId)
        : availablePathways.find((pathway) => pathway.pathwayId === parent.pathwayId);

    if (!moduleParent) {
      return false;
    }

    let userModule: UserCourseModule | UserPathwayModule | undefined;
    userModule =
      'courseId' in parent
        ? userCourseModules?.find(
            (pickedModule) => pickedModule.moduleId === moduleId && pickedModule.courseId === parent.courseId,
          )
        : userPathwayModules?.find(
            (pickedModule) => pickedModule.moduleId === moduleId && pickedModule.pathwayId === parent.pathwayId,
          );

    if (userModule) {
      return !moduleParent.hidden;
    }

    return false;
  };

  const addModule = async (moduleId: string, parent: { courseId?: string; pathwayId?: string }) => {
    if ('pathwayId' in parent) {
      const pathway = availablePathways.find((pathway) => pathway.pathwayId === parent.pathwayId);

      if (pathway) {
        await addPathwayModule({
          pathway,
          moduleId,
          status: MODULE_PROGRESS_STATUS.IN_PROGRESS,
        });
      }
    }

    const course = availableCourses.find((course) => course.courseId === parent.courseId);

    if (course) {
      await addCourseModule({
        course,
        moduleId,
        status: MODULE_PROGRESS_STATUS.IN_PROGRESS,
      });
    }
  };

  const handleAddModule = async (moduleId: string, parent: { courseId?: string; pathwayId?: string }) => {
    if (!isModuleOnBoard(moduleId, parent)) {
      await addModule(moduleId, parent);
    }
  };

  return { handleAddModule };
};
