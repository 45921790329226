import { AssessmentResult, Category, CourseModule } from '.';

export enum CertificationType {
  CERTIFICATE_OF_COMPLETION = 'certificateOfCompletion',
  CERTIFICATION = 'certification',
}

export interface Course {
  courseId: string;
  name: string;
  status: number;
  duration: number;
  fullDescription: string;
  courseBadgeImage: {
    url?: string;
    responsiveImage: {
      base64?: string;
    };
  };
  badgeTemplateId: string;
  certificationType?: CertificationType;
  assessmentResult?: AssessmentResult;
  modules: CourseModule[];
  assessmentDuration: number;
  assessmentQuestions: {
    id: string;
  }[];
  addedModules: CourseModule[];
  learningObjectives: ObjectivesDescription[];
  assessmentIntro: string;
  assessmentOutro: string;
  assessmentStatus: number;
  category: Category;
  hidden?: boolean;
}

export interface ObjectivesDescription {
  description: string;
}
