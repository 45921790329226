import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IdsModal, IdsTextInput } from '@emergn-infinity/ids-react';

import './search-modal.scss';
import SearchFilters from './search-filters';
import SearchResults from './search-results';
import ResultsNumber from './results-number';
import EmptySearch from './empty-search';
import { NUMBER_OF_RESULTS_PER_PAGE } from './search-modal-constants';
import { getFilteredResults, limitObjectItems } from './search-modal-helpers';

import { useWindowDimensions } from '@/hooks';
import Loading from '@/app/loading';
import { getRequest } from '@/server-actions/common';
import { UserPathwayModule, UserCourseModule, Pathway, Course } from '@/interfaces';

type SearchModalProps = {
  closeModal: () => void;
};

const SearchModal: React.FC<SearchModalProps> = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearch, setCurrentSearch] = useState('');
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<any>({});
  const [numberOfResultsDisplayed, setNumberOfResultsDisplayed] = useState(0);
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  // as filtering is done on client side, we don't need to fetch new data when filters are changed
  const [isFetchRequired, setIsFetchRequired] = useState(false);
  const [userCourseModules, setUserCourseModules] = useState<UserCourseModule[]>([]);
  const [userPathwayModules, setUserPathwayModules] = useState<UserPathwayModule[]>([]);
  const [availableCourses, setAvailableCourses] = useState<Course[]>([]);
  const [availablePathways, setAvailablePathways] = useState<Pathway[]>([]);

  const { isSmallMobile } = useWindowDimensions();

  const debouncedSearch = useMemo(
    () =>
      debounce((inputVal) => {
        setCurrentSearch(inputVal);
        setIsFetchRequired(true);
        setIsLoading(true);
      }, 500),
    [setCurrentSearch],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Use a short timeout to ensure the input has rendered
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (currentSearch.length > 2 && isFetchRequired) {
      const fetchResults = async () => {
        const data = await getRequest('explore/search-user-content', undefined, {
          searchTerm: currentSearch,
        });

        if (data) {
          const { emptySearch, ...results } = data;

          const numberOfResultsFetched = Object.values(results).flat().length;
          setNumberOfResultsDisplayed(
            numberOfResultsFetched > NUMBER_OF_RESULTS_PER_PAGE ? NUMBER_OF_RESULTS_PER_PAGE : numberOfResultsFetched,
          );
          setIsSearchEmpty(emptySearch);
          setSearchResults(results);

          setIsLoading(false);
          setIsFetchRequired(false);
        }
      };

      fetchResults();
    } else if (currentSearch.length <= 2) {
      // reset search results when search term is cleared
      setSearchResults({});
      setSearchFilters([]);
      setNumberOfResultsDisplayed(0);
    }
  }, [currentSearch, isFetchRequired]);

  useEffect(() => {
    const fetchModules = async () => {
      const [userCourseModules, userPathwayModules, availableCourses, availablePathways] = await Promise.all([
        getRequest('kanban-board/get-course-modules', ['UserCourseModules']),
        getRequest('kanban-board/get-pathway-modules', ['UserPathwayModules']),
        getRequest('explore/get-user-courses', ['AvailableCourses']),
        getRequest('explore/get-user-pathways', ['AvailablePathways']),
      ]);

      setUserCourseModules(userCourseModules || []);
      setUserPathwayModules(userPathwayModules || []);
      setAvailableCourses(availableCourses || []);
      setAvailablePathways(availablePathways || []);
    };

    fetchModules();
  }, []);

  const handleFiltersChange = (filter: string) => {
    const updatedSearchFilters = searchFilters.includes(filter)
      ? searchFilters.filter((f) => f !== filter)
      : [...searchFilters, filter];

    setSearchFilters(updatedSearchFilters);
  };

  const handleClearFilters = () => {
    setSearchFilters([]);
  };

  const filteredResultsToDisplay = limitObjectItems(
    getFilteredResults(searchResults, searchFilters),
    numberOfResultsDisplayed,
  );

  return (
    <IdsModal
      id="search-modal"
      isOpen
      closeHandler={closeModal}
      ignoreOverlayClick
      isFullscreen={isSmallMobile}
      customClasses="SearchModal"
    >
      <div slot="header" className="ModalHeader">
        <IdsTextInput
          idValue="search-input"
          placeholder="What are you looking for?"
          changeHandler={debouncedSearch}
          iconRight="ui-search-search"
          isClearable={!!currentSearch}
          clearHandler={() => setCurrentSearch('')}
          themeName="vfq"
          innerRef={inputRef}
        />
      </div>
      <div slot="main" className={currentSearch.length < 2 ? 'WithEmptySearch' : ''}>
        {currentSearch.length > 2 ? (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <div className="SearchContent">
                {!isSearchEmpty && (
                  <SearchFilters
                    updateFilters={handleFiltersChange}
                    selectedFilters={searchFilters}
                    clearFilters={handleClearFilters}
                  />
                )}
                <SearchResults
                  content={filteredResultsToDisplay}
                  searchTerm={currentSearch}
                  emptySearch={isSearchEmpty}
                  userCourseModules={userCourseModules}
                  userPathwayModules={userPathwayModules}
                  availableCourses={availableCourses}
                  availablePathways={availablePathways}
                />
              </div>
            )}
          </>
        ) : (
          <EmptySearch />
        )}
      </div>
      <div slot="footer">
        {!isSearchEmpty && !isLoading && (
          <ResultsNumber
            numberOfResultsDisplayed={Object.values(filteredResultsToDisplay).flat().length}
            totalNumberOfResults={Object.values(getFilteredResults(searchResults, searchFilters)).flat().length}
            loadMoreResults={() => setNumberOfResultsDisplayed(numberOfResultsDisplayed + NUMBER_OF_RESULTS_PER_PAGE)}
          />
        )}
      </div>
    </IdsModal>
  );
};

export default SearchModal;
