import React from 'react';
import { IdsLink, IdsText } from '@emergn-infinity/ids-react';

type ResultsNumberProps = {
  numberOfResultsDisplayed: number;
  totalNumberOfResults: number;
  loadMoreResults: () => void;
};

const ResultsNumber: React.FC<ResultsNumberProps> = ({
  numberOfResultsDisplayed,
  totalNumberOfResults,
  loadMoreResults,
}) => {
  if (totalNumberOfResults === 0) {
    return null;
  }

  return (
    <div className="ResultsNumberContainer">
      <IdsText component="span" size="sm" customClasses="ResultsNumber">
        Showing {numberOfResultsDisplayed} of {totalNumberOfResults} results
      </IdsText>
      {numberOfResultsDisplayed < totalNumberOfResults && (
        <IdsLink
          isInline
          variant="brand"
          rest={{
            onClick: loadMoreResults,
          }}
        >
          Load more results
        </IdsLink>
      )}
    </div>
  );
};

export default ResultsNumber;
