'use client';

import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { IdsButton, IdsMainNavigation, IdsDivider } from '@emergn-infinity/ids-react';
import { type MenuItem } from '@emergn-infinity/ids-react/node_modules/@emergn-infinity/ids-core/loader';

import { useWindowDimensions } from '@/hooks';
import { AppSections } from '@/interfaces/general/app-sections';
import { UserSubscription } from '@/interfaces/user';
import { AuthToken } from '@/services/auth.service';
import { deleteTokenFromCookies } from '@/server-actions/token';
import { AppRoutes } from '@/constants/general';

import SearchModal from '../search-modal/search-modal';
import './nav-bar.scss';
import { slugify } from '@/helpers/strings';
import ContactUsModal, { SupportContactModalType } from '../contact-us-modal/contact-us-modal';
import ContactSupportModal from '../concat-support-modal/contact-support-modal';

const BASE_PATH = '/app';

const handleLogout = async () => {
  const auth = new AuthToken();

  await Promise.all([auth.logout(), deleteTokenFromCookies()]);
};

const getSectionSelected = (path: string) => {
  const mainRoute = path.split('/')[1];

  if (mainRoute === AppSections.ACCOUNT) {
    return path.split('/')[2];
  }

  return mainRoute === 'kanban-board' ? AppSections.KANBAN_BOARD : mainRoute;
};

type NavbarProps = {
  pageAccess: string[];
  userInitials: string;
  userSubscription?: UserSubscription;
  isBetaUser: boolean;
  isEmergnUser: boolean;
};

const NavBar: React.FC<NavbarProps> = ({ pageAccess, userInitials, userSubscription, isBetaUser, isEmergnUser }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isContactUsModal, setIsContactUsModal] = useState(false);
  const [contactSupportModalType, setContactSupportModalType] = useState<SupportContactModalType | null>(null);

  const pathname = usePathname();
  const { isSmallMobile, isMobile } = useWindowDimensions();

  const sectionSelected = getSectionSelected(pathname);
  const isPrivilegedUser = isBetaUser || isEmergnUser || userSubscription;

  useEffect(() => {
    setIsSearchOpen((isOpen) => {
      return isOpen ? false : isOpen;
    });
  }, [pathname]);

  const userHasAccessTo = (section: AppSections) => {
    if (section === AppSections.ADMIN) {
      return (
        pageAccess.includes(AppSections.ADMIN) ||
        pageAccess.includes(AppSections.ADMIN_USERS) ||
        pageAccess.includes(AppSections.ADMIN_B2C_USERS)
      );
    }

    return pageAccess.includes(section);
  };

  const closeMenu = () => {
    if (isSmallMobile) {
      setIsMobileMenuOpen(false);
    }
  };

  const getNavButton = (options: {
    icon: string;
    label: string;
    selected: boolean;
    href?: string;
    themeName?: string;
    iconRight?: string;
    iconOnlyNavButton?: boolean;
    title?: string;
    onClick?: () => void;
  }) => {
    const getHref = () => {
      const { href } = options;
      if (href === undefined) {
        return '';
      }
      return href.includes('mailto') ? href : BASE_PATH + href;
    };
    return {
      iconLeft: options.icon,
      label: options.iconOnlyNavButton ? '' : options.label,
      href: getHref(),
      onClick: options.onClick ?? closeMenu,
      selected: options.selected,
      themeName: options.themeName,
      iconRight: options.iconRight,
      iconOnlyNavButton: options.iconOnlyNavButton,
      title: options.iconOnlyNavButton ? options.title : '',
      'data-elp-testid': 'nav-bar-' + slugify(options.label),
      className: 'MenuItem' + options.label.replace(' ', ''),
      'data-text': options.label,
    };
  };

  const getSubMenuButton = (options: {
    icon: string;
    label: string;
    href: string;
    selected: boolean;
    onClick?: Function;
    themeName?: string;
  }) => {
    const clickHandler = options.onClick ? { onClick: options.onClick } : {};
    return {
      ...getNavButton({
        icon: options.icon,
        label: options.label,
        href: options.href,
        selected: options.selected,
        themeName: options.themeName,
      }),
      variant: 'option',
      type: 'action',
      'data-elp-testid': 'sub-menu' + '-' + slugify(options.label),
      ...clickHandler,
    };
  };

  let defaultSections: MenuItem[] = [
    getNavButton({
      icon: 'themes-geography-compass',
      label: 'Explore',
      href: isPrivilegedUser ? AppRoutes.ExploreForYou : AppRoutes.ExploreCoursesAndPathways,
      selected: sectionSelected === AppSections.EXPLORE,
      themeName: 'vfq',
    }),
    getNavButton({
      icon: 'ui-home-dashboard',
      label: 'Board',
      href: AppRoutes.KanbanBoard,
      selected: sectionSelected === AppSections.KANBAN_BOARD,
      themeName: 'vfq',
    }),
  ];

  if (!isPrivilegedUser) {
    defaultSections = defaultSections.toReversed();
  }

  const getAdminRoute = () => {
    if (pageAccess.includes(AppSections.ADMIN)) return '/admin';
    if (pageAccess.includes(AppSections.ADMIN_USERS)) return '/admin/users';
    if (pageAccess.includes(AppSections.ADMIN_B2C_USERS)) return '/admin/b2c-users';
    return '';
  };

  const privateSections: any[] = [
    userHasAccessTo(AppSections.CHATBOT) &&
      getNavButton({
        icon: 'themes-miscellaneous-ai',
        label: 'AI Consultant',
        href: '/chatbot',
        selected: sectionSelected === AppSections.CHATBOT,
        themeName: 'vfq',
        iconRight: 'themes-technology-beta',
      }),
  ].filter(Boolean);

  const contactUsSection: any[] = [
    getNavButton({
      icon: 'ui-alert-question_circle',
      label: 'Contact us',
      href: undefined,
      selected: false,
      themeName: '',
      iconRight: '',
      iconOnlyNavButton: !isMobile,
      title: 'Contact us',
      onClick: () => setIsContactUsModal(true),
    }),
  ].filter(Boolean);

  const menuItems = [...defaultSections, ...privateSections];

  const privateSubSections = (callback: Function) =>
    [
      userHasAccessTo(AppSections.CHECKLIST) &&
        callback({
          icon: 'ui-form-check_circle',
          label: 'Checklist',
          href: '/checklist',
          selected: sectionSelected === AppSections.CHECKLIST,
        }),
      userHasAccessTo(AppSections.INSTRUCTOR) &&
        callback({
          icon: 'themes-learning-school_teacher',
          label: 'Instructor',
          href: '/instructor',
          selected: sectionSelected === AppSections.INSTRUCTOR,
        }),
      getAdminRoute() &&
        callback({
          icon: 'ui-user-single_settings',
          label: 'Admin',
          href: getAdminRoute(),
          selected: sectionSelected === AppSections.ADMIN,
        }),
      userHasAccessTo(AppSections.ANALYTICS) &&
        callback({
          icon: 'data-graph-stats_ascend',
          label: 'Analytics',
          href: '/analytics/overview',
          selected: sectionSelected === AppSections.ANALYTICS,
        }),
    ].filter(Boolean);

  const publicSectionsSubMenu = () => {
    const subMenus = [
      getSubMenuButton({
        icon: 'ui-user-single_idcard',
        label: 'Personal Information',
        href: AppRoutes.AccountPersonalInformation,
        selected: sectionSelected === AppSections.PERSONAL_INFORMATION,
      }),
    ];

    if (userSubscription) {
      subMenus.push(
        getSubMenuButton({
          icon: 'ui-user-single_payments',
          label: 'Subscription & Billing',
          href: AppRoutes.AccountSubscriptionAndBilling,
          selected: sectionSelected === AppSections.SUBSCRIPTION_AND_BILLING,
        }),
      );
    }

    subMenus.push(
      getSubMenuButton({
        icon: 'ui-validation-view_off',
        label: 'Privacy',
        href: AppRoutes.AccountPrivacy,
        selected: sectionSelected === AppSections.PRIVACY,
      }),
      getSubMenuButton({
        icon: 'ui-validation-key',
        label: 'Password & Security',
        href: AppRoutes.AccountPassword,
        selected: sectionSelected === AppSections.PASSWORD,
      }),
    );

    return subMenus;
  };

  const privateSectionSubMenu = () => {
    if (userHasAccessTo(AppSections.INSTRUCTOR) || getAdminRoute() || userHasAccessTo(AppSections.ANALYTICS)) {
      return [{ variant: 'divider' }, ...privateSubSections(getSubMenuButton)];
    }
    return [];
  };

  const avatar = {
    url: isMobile ? BASE_PATH + AppRoutes.AccountPersonalInformation : undefined,
    initials: userInitials,
    themeName: 'vfq-avatar-courses',
    subMenu: [
      ...publicSectionsSubMenu(),
      ...privateSectionSubMenu(),
      { variant: 'divider' },
      getSubMenuButton({
        icon: 'ui-login-logout',
        label: 'Logout',
        href: '',
        selected: false,
        onClick: handleLogout,
      }),
    ],
    onClick: closeMenu,
  };

  if (isMobile) {
    menuItems.push(...privateSubSections(getNavButton));
  }

  menuItems.push(...contactUsSection);

  return (
    <>
      <header className="NavbarContainer">
        <IdsMainNavigation
          logoAlt="Emergn logo"
          logoSrc={BASE_PATH + `/assets/vfq-ds/images/logos/Praxis-by-Emergn-black.svg`}
          logoUrl={BASE_PATH + `${isPrivilegedUser ? AppRoutes.ExploreForYou : AppRoutes.KanbanBoard}`}
          menuItems={menuItems}
          avatar={avatar}
          themeName="vfq"
          customClasses="CustomNavbar"
          cta={{
            children: 'Search',
            color: 'neutral',
            variant: 'tertiary',
            onClick: () => {
              setIsSearchOpen(true);
              if (isSmallMobile) setIsMobileMenuOpen(false);
            },
            iconLeft: 'ui-search-search',
          }}
          burger={{ color: 'neutral', variant: 'tertiary', iconSize: 'sm' }}
          container={{ fixedWidthSize: 'xl' }}
          menuIsOpen={isMobileMenuOpen}
        >
          <div slot="utility-slot">
            {isMobile && (
              <div className="MobileLogoutWrapper">
                <IdsDivider />
                <IdsButton variant="tertiary" iconLeft="ui-login-logout" padding="lg" clickHandler={handleLogout}>
                  Logout
                </IdsButton>
              </div>
            )}
          </div>
        </IdsMainNavigation>
      </header>
      {isSearchOpen && <SearchModal closeModal={() => setIsSearchOpen(false)} />}
      {isContactUsModal && (
        <ContactUsModal
          openContactSupportModal={(type: SupportContactModalType) => setContactSupportModalType(type)}
          closeHandler={() => setIsContactUsModal(false)}
        />
      )}
      {contactSupportModalType && (
        <ContactSupportModal
          closeModal={() => setContactSupportModalType(null)}
          openContactUsModal={() => setIsContactUsModal(true)}
          type={contactSupportModalType!}
        />
      )}
    </>
  );
};

export default NavBar;
