import React from 'react';
import { useRouter } from 'next/navigation';
import { IdsLink, IdsText } from '@emergn-infinity/ids-react';

import { getHighlightedText, findMatchingSentence } from './search-modal-helpers';

import { getItemData } from '@/helpers';
import { ModelType } from '@/constants/explore';
import { SearchItemType } from '@/interfaces/explore';

type ResultItemProps = {
  item: SearchItemType;
  searchTerm: string;
  contentType: ModelType;
  emptySearch: boolean;
  handleAddModule: (moduleId: string, parent: { courseId?: string; pathwayId?: string }) => Promise<void>;
};

const ResultItem: React.FC<ResultItemProps> = ({ item, searchTerm, contentType, emptySearch, handleAddModule }) => {
  const itemTitle = item.title || item.name || '';

  const router = useRouter();
  const { itemId } = getItemData(contentType, item);
  const { selectedSentence, infoFromChapter } = findMatchingSentence(item, searchTerm, itemTitle, contentType, emptySearch);

  const getItemInfo = () => {
    if (infoFromChapter) {
      return {
        title: infoFromChapter['title'],
        link: `/explore/${contentType}s/${itemId}/chapter/${infoFromChapter['chapterId']}?searchTerm=${searchTerm}`,
      };
    }

    if (contentType === ModelType.Courses_Pathways) {
      if (item.sectionId && item.sectionName) {
        return {
          title: item.sectionName,
          link: item.courseId
            ? `/module/${item.moduleId}?courseId=${item.courseId}&lessonId=${item.lessonId}&sectionId=${item.sectionId}&searchTerm=${searchTerm}`
            : `/module/${item.moduleId}?pathwayId=${item.pathwayId}&lessonId=${item.lessonId}&sectionId=${item.sectionId}&searchTerm=${searchTerm}`,
        };
      }

      if (item.moduleId) {
        return {
          title: item.moduleName,
          link: item.courseId
            ? `/module/${item.moduleId}?courseId=${item.courseId}&searchTerm=${searchTerm}`
            : `/module/${item.moduleId}?pathwayId=${item.pathwayId}&searchTerm=${searchTerm}`,
        };
      }

      return {
        title: item.name,
        link: item.courseId
          ? `/explore/courses/${item.courseId}?searchTerm=${searchTerm}`
          : `/explore/pathways/${item.pathwayId}?searchTerm=${searchTerm}`,
      };
    }

    return { title: itemTitle, link: `/explore/${contentType}s/${itemId}?searchTerm=${searchTerm}` };
  };

  const renderParentLink = (type: string, label: string, link: string) => {
    return (
      <div className="ParentLink">
        <IdsText component="span" weight="bold" size="sm">
          {type}
        </IdsText>
        <IdsLink rest={{ onClick: () => handleLinkClick(link) }} isInline variant="brand">
          {label}
        </IdsLink>
      </div>
    );
  };

  const handleLinkClick = async (link: string) => {
    if (contentType === ModelType.Courses_Pathways && item.moduleId && link.includes('module')) {
      // handle the case where the module is not on board
      await handleAddModule(item.moduleId, item.courseId ? { courseId: item.courseId } : { pathwayId: item.pathwayId });
    }

    router.push(link);
  };

  const itemInfo = getItemInfo();

  return (
    <div className="SearchResultDetails">
      <IdsLink size="lg" rest={{ onClick: () => handleLinkClick(itemInfo.link) }} isInline>
        {getHighlightedText(itemInfo.title || '', searchTerm, true)}
      </IdsLink>
      {selectedSentence && (
        <IdsText customClasses="SelectedSentence" size="md">
          {getHighlightedText(selectedSentence, searchTerm, true)}
        </IdsText>
      )}
      {contentType === ModelType.Tool && item.externalLink && !emptySearch && (
        <IdsLink href={item.externalLink} target="_blank" iconRight="ui-action-expand" variant="brand">
          Open in {item.platform}
        </IdsLink>
      )}
      {contentType === ModelType.Book &&
        infoFromChapter &&
        renderParentLink('Book', itemTitle, `/explore/${contentType}s/${itemId}`)}
      {contentType === ModelType.Courses_Pathways &&
        item.sectionId &&
        item.moduleName &&
        renderParentLink(
          'Module',
          item.moduleName,
          item.courseId
            ? `/module/${item.moduleId}?courseId=${item.courseId}`
            : `/module/${item.moduleId}?pathwayId=${item.pathwayId}`,
        )}
      {contentType === ModelType.Courses_Pathways &&
        item.moduleId &&
        item.name &&
        renderParentLink(
          'Course',
          item.name,
          item.courseId ? `/explore/courses/${item.courseId}` : `/explore/pathways/${item.pathwayId}`,
        )}
    </div>
  );
};

export default ResultItem;
