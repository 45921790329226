import { b64toBlob } from './b64-to-blob';

export const download = (b64Data: string, filename: string, mimeType: string) => {
  const blob = b64toBlob(b64Data, mimeType);
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.append(a);
  a.setAttribute('style', 'display: none');
  a.setAttribute('href', url);
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
};
