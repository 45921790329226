import { SupportContactModalType } from '@/components/contact-us-modal/contact-us-modal';
import { postRequest } from './common';

export async function contactSupport(params: {
  type: SupportContactModalType;
  section: string;
  description: string;
  screenResolution: string;
  browserDetails: string;
}) {
  return postRequest('support/provide-feedback', params);
}
