import { Environments } from '@/interfaces';

const DEFAULT_CONTENT: { label: string; value: string; icon: string }[] = [
  { label: 'Courses & Pathways', value: 'courses-pathways', icon: 'themes-learning-school_elearning' },
  { label: 'Books & Chapters', value: 'books', icon: 'themes-learning-book_library' },
  { label: 'Tools', value: 'tools', icon: 'ui-settings-wrench' },
  { label: 'Techniques', value: 'techniques', icon: 'ui-file-multiple_doc' },
];

// temporarily hide insights on Prod
export const AVAILABLE_CONTENT =
  process.env.currentEnvironment === Environments.PRODUCTION
    ? DEFAULT_CONTENT
    : [
        ...DEFAULT_CONTENT,
        {
          label: 'Insights',
          value: 'insights',
          icon: 'themes-learning-school_idea',
        },
      ];

export const NUMBER_OF_RESULTS_PER_PAGE = 15;
