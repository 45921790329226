import React from 'react';
import { IdsDivider, IdsIcon, IdsText, IdsLink } from '@emergn-infinity/ids-react';

import ResultItem from './result-item';
import { AVAILABLE_CONTENT } from './search-modal-constants';

import { ModelType } from '@/constants/explore';
import { useModuleActions } from '@/hooks/use-module-actions';
import type { Course, Pathway, UserCourseModule, UserPathwayModule } from '@/interfaces';

const renderEmptyFilteredResults = () => {
  return (
    <div className="EmptyFilterSearchResults">
      <IdsIcon icon="ui-search-search_remove" size="lg" />
      <IdsText size="md" alignment="center">
        There are no records to display for the selected filter(s)
      </IdsText>
    </div>
  );
};

const renderNoResultsFound = (searchTerm: string) => {
  return (
    <div className="EmptySearchResults">
      <IdsText size="md" weight="bold">
        No results for &quot;{searchTerm}&quot;
      </IdsText>
      <IdsText size="md" isInline>
        {"Double-check for typos or try searching for different keywords. Still no luck? We'd love to "}
        <IdsLink
          size="md"
          isInline
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=G1ol0Dtmpk-O3ecCOHJyd0pt5_jK_KROuMbPmPbvUsVUN09CUjhTSkdCODlaS1M0RUZDOUZDVVhaMCQlQCN0PWcu"
          target="_blank"
        >
          hear your feedback
        </IdsLink>
        {' to enhance the search experience.'}
      </IdsText>
      <IdsText size="md" weight="bold">
        Meanwhile, here is some content that you might find interesting:
      </IdsText>
    </div>
  );
};

type SearchResultsProps = {
  content: object;
  searchTerm: string;
  emptySearch: boolean;
  userCourseModules: UserCourseModule[];
  userPathwayModules: UserPathwayModule[];
  availableCourses: Course[];
  availablePathways: Pathway[];
};

const SearchResults: React.FC<SearchResultsProps> = ({
  content = {},
  searchTerm,
  emptySearch,
  userCourseModules,
  userPathwayModules,
  availableCourses,
  availablePathways,
}) => {
  const { handleAddModule } = useModuleActions(userCourseModules, userPathwayModules, availableCourses, availablePathways);

  return (
    <>
      {Object.values(content).flat().length === 0 ? (
        renderEmptyFilteredResults()
      ) : (
        <>
          {emptySearch && renderNoResultsFound(searchTerm)}
          {AVAILABLE_CONTENT.map((type, index) => {
            const contentItems = content[type.value];

            if (contentItems && contentItems.length > 0) {
              return (
                <div className="SearchResultsItem" key={type.value}>
                  <div className="SearchResultsHeader">
                    <IdsIcon icon={type.icon} />
                    <IdsText component="span" weight="bold" size="md">
                      {type.label}
                    </IdsText>
                  </div>
                  <div key={type.value} className="ResultsByContentType">
                    {content[type.value]?.map((item) => (
                      <ResultItem
                        key={item.name || item.title}
                        item={item}
                        searchTerm={searchTerm}
                        contentType={
                          type.value === ModelType.Courses_Pathways
                            ? ModelType.Courses_Pathways
                            : (type.value.slice(0, -1).toLowerCase() as ModelType)
                        }
                        emptySearch={emptySearch}
                        handleAddModule={handleAddModule}
                      />
                    ))}
                  </div>
                  {index !== AVAILABLE_CONTENT.length - 1 && <IdsDivider />}
                </div>
              );
            }
          })}
        </>
      )}
    </>
  );
};

export default SearchResults;
