import React from 'react';
import { IdsCard, IdsIcon, IdsModal, IdsText } from '@emergn-infinity/ids-react';

import './contact-us-modal.scss';

export type ContactUsModalProps = {
  closeHandler: () => void;
  openContactSupportModal: (type: SupportContactModalType) => void;
};

export type SupportContactModalType = 'ask-question' | 'share-feedback' | 'report-issue';

const CardRenderer = (
  header: string,
  description: string,
  icon: string,
  type: SupportContactModalType,
  openContactSupportModal: (type: SupportContactModalType) => void,
  closeHandler: () => void,
) => {
  const handleCardClick = (type: SupportContactModalType) => {
    openContactSupportModal(type);
    closeHandler();
  };
  return (
    <IdsCard spacing="sm" padding="sm" clickHandler={() => handleCardClick(type)}>
      <div slot="slot1" className="CardContentWrapper">
        <IdsIcon icon={icon} size="lg" />
        <div className="CardTextWrapper">
          <div>
            <IdsText component="h6" size="xs" weight="bold">
              {header}
            </IdsText>
            <IdsText weight="light">{description}</IdsText>
          </div>
          <IdsIcon icon="ui-navigation-angle_right" size="sm" />
        </div>
      </div>
    </IdsCard>
  );
};

const ContactUsModal: React.FC<ContactUsModalProps> = ({ closeHandler, openContactSupportModal }) => {
  return (
    <IdsModal
      isOpen
      closeHandler={() => closeHandler()}
      size="md"
      isFullyScrollable
      customClasses="ContactUsModalTypeSelection"
    >
      <div slot="header">
        <IdsText size="sm" weight="bold" component="h2">
          Contact us
        </IdsText>
      </div>
      <div slot="main" className="CardsContainer">
        {[
          CardRenderer(
            'Ask a question',
            'Have a question or need more information? Let us know, and we’ll be happy to assist you.',
            'media-chat-bubble_question',
            'ask-question',
            openContactSupportModal,
            closeHandler,
          ),
          CardRenderer(
            'Share feedback or suggest an idea',
            'Your thoughts matter! Share your feedback or suggest new ideas.',
            'themes-miscellaneous-star',
            'share-feedback',
            openContactSupportModal,
            closeHandler,
          ),
          CardRenderer(
            'Report an issue',
            'Encountered an issue? Let us know so we can address it as quickly as possible.',
            'media-chat-bubble_warning',
            'report-issue',
            openContactSupportModal,
            closeHandler,
          ),
        ]}
      </div>
    </IdsModal>
  );
};
export default ContactUsModal;
