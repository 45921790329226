import { ModelType } from '@/constants/explore';
import { Book, Chapter, Insight, Technique, Tool, SearchItemType } from '@/interfaces/explore';

export const getItemData = (
  modelType: Omit<ModelType, 'course' | 'pathway'>,
  item: Book | Chapter | Insight | Technique | Tool | SearchItemType,
): { itemId: string; itemTitle: string } => {
  let itemId = '';
  let itemTitle = '';

  switch (modelType) {
    case ModelType.Book: {
      itemId = (item as Book).bookId;
      itemTitle = (item as Book).title;
      break;
    }
    case ModelType.Chapter: {
      itemId = (item as Chapter).chapterId;
      itemTitle = (item as Chapter).title;
      break;
    }
    case ModelType.Insight: {
      itemId = (item as Insight).insightId;
      itemTitle = (item as Insight).name;
      break;
    }
    case ModelType.Technique: {
      itemId = (item as Technique).techniqueId;
      itemTitle = (item as Technique).name;
      break;
    }
    case ModelType.Tool: {
      itemId = (item as Tool).toolId;
      itemTitle = (item as Tool).name;
      break;
    }
  }

  return {
    itemId,
    itemTitle,
  };
};
