import React from 'react';
import { IdsDropdown, IdsTag, IdsTagGroup, IdsText } from '@emergn-infinity/ids-react';

import { AVAILABLE_CONTENT } from './search-modal-constants';

import { useWindowDimensions } from '@/hooks';

type SearchFiltersProps = {
  updateFilters: (filter: string) => void;
  selectedFilters: string[];
  clearFilters: () => void;
};

const SearchFilters: React.FC<SearchFiltersProps> = ({ updateFilters, selectedFilters, clearFilters }) => {
  const { isMobile } = useWindowDimensions();

  const isSelected = (tag: string) => selectedFilters.includes(tag);

  return (
    <>
      {isMobile ? (
        <IdsDropdown
          idValue="search-filters"
          items={AVAILABLE_CONTENT}
          isMultiSelect
          changeHandler={updateFilters}
          clearHandler={clearFilters}
          placeholder="Filter by"
          themeName="vfq"
        />
      ) : (
        <div className="SearchFiltersDesktop">
          <IdsText size="sm">Filter by:</IdsText>
          <IdsTagGroup>
            {AVAILABLE_CONTENT.map((filter) => {
              const { label, icon, value } = filter;

              return (
                <IdsTag
                  key={value}
                  themeName="vfq"
                  isActive={isSelected(value)}
                  clickHandler={() => updateFilters(value)}
                  rest={{ value, type: 'button' }}
                  iconLeft={icon}
                >
                  {label}
                </IdsTag>
              );
            })}
          </IdsTagGroup>
        </div>
      )}
    </>
  );
};

export default SearchFilters;
