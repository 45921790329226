export const durationWithUnits = (duration: number): string => {
  let durationWithUnits = `${duration} minute${duration === 1 ? '' : 's'}`;

  if (duration === 60) {
    durationWithUnits = '1 hour';
  } else if (duration > 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    durationWithUnits = `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
  }

  return durationWithUnits;
};

export const minutesToHours = (duration: number, mode?: string) => {
  const hours = Math.trunc(duration / 60);
  const hoursLabel = hours + (hours > 1 ? ' Hours' : ' Hour');
  const minutes = duration % 60;
  const minutesLabel = minutes > 1 ? ' Minutes' : ' Minute';

  const timeSpacing = minutes > 0 ? ' ' : '';

  const minutesText = minutes + (mode === 'full' ? minutesLabel : ' Min');
  return `${hours > 0 ? hoursLabel + timeSpacing : ''}${minutes > 0 ? minutesText : ''}`;
};
