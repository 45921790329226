import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import {
  IdsButton,
  IdsButtonGroup,
  IdsDropdown,
  IdsFieldWrapper,
  IdsModal,
  IdsText,
  IdsTextarea,
} from '@emergn-infinity/ids-react';

import type { ItemGroup } from '@emergn-infinity/ids-react/node_modules/@emergn-infinity/ids-core/loader';

import { contactSupport } from '@/server-actions/contact-support';
import { useNotification } from '@/providers/notification-context';
import { useWindowDimensions, useAppSelector } from '@/hooks';

import { SupportContactModalType } from '../contact-us-modal/contact-us-modal';
import FormWrapper from '../common/form-wrapper';

import { UserRole, UserSubscriptionType } from '@/interfaces';
import { AppSections } from '@/interfaces/general/app-sections';
import { getRequest } from '@/server-actions/common';

import './contact-support-modal.scss';

const textResolver = {
  'ask-question': {
    modalHeader: 'Ask a question',
    dropdownHelperText: 'If your question is about something else, select a different page or choose “General” question.',
    dropdownHeaderText: 'General question ',
    textareaLabel: 'Your question',
    textareaPlaceholder: 'Type your question here (e.g., "How do I achieve the Ultimate Product Manager Badge?")',
    submitButtonTitle: 'Submit question',
    successNotificationTitle: 'Thank you for your question!',
    errorNotificationTitle: 'Your question could not be submitted.',
  },
  'share-feedback': {
    modalHeader: 'Share feedback or suggest an idea',
    dropdownHelperText:
      'If your feedback or idea is about something else, select a different page or choose “General” feedback.',
    dropdownHeaderText: 'General feedback ',
    textareaLabel: 'Your feedback or idea',
    textareaPlaceholder: 'Type your feedback or idea here (e.g., "Adding the option to change the interface theme.")',
    submitButtonTitle: 'Submit feedback',
    successNotificationTitle: 'Thank you for your feedback!',
    errorNotificationTitle: 'Your feedback could not be submitted.',
  },
  'report-issue': {
    modalHeader: 'Report an issue',
    dropdownHelperText: 'If the issue is related to another page, switch pages or describe “General” issue.',
    dropdownHeaderText: 'General issue',
    textareaLabel: 'Issue description',
    textareaPlaceholder:
      'Type the issue description here (e.g., "I cant submit the form because the submit button is unresponsive.")',
    submitButtonTitle: 'Submit issue',
    successNotificationTitle: 'Thank you for the issue report!',
    errorNotificationTitle: 'Your issue report could not be submitted.',
  },
};

type ContactSupportModalProps = {
  closeModal: () => void;
  openContactUsModal: () => void;
  type: SupportContactModalType;
};

const ContactSupportModal: React.FC<ContactSupportModalProps> = ({ closeModal, openContactUsModal, type }) => {
  const [pageAccess, setPageAccess] = useState<string[]>([]);
  const [description, setDescription] = useState('');

  const pathname = usePathname();

  const { isSmallMobile } = useWindowDimensions();

  const { openErrorNotification, openSuccessNotification } = useNotification();
  const {
    user: { user },
  } = useAppSelector((state) => state);

  const getPageAccess = async () => {
    const pageAccess = await getRequest('user/get-page-access', ['PageAccess']);
    setPageAccess(pageAccess);
  };

  useEffect(() => {
    getPageAccess();
  }, []);

  const userHasAccessTo = (section: AppSections) => {
    if (section === AppSections.ADMIN) {
      return (
        pageAccess.includes(AppSections.ADMIN) ||
        pageAccess.includes(AppSections.ADMIN_USERS) ||
        pageAccess.includes(AppSections.ADMIN_B2C_USERS)
      );
    }
    return pageAccess.includes(section);
  };

  const generalGroup: ItemGroup = {
    group: 'General',
    items: [
      {
        value: textResolver[type].dropdownHeaderText,
        label: textResolver[type].dropdownHeaderText,
      },
    ],
  };

  const getSections = () => {
    const isB2b = !user.subscription && user.clientId;
    const nonEmergnb2bLearner = user.role === UserRole.LEARNER && !user.type.isEmergnUser && isB2b;

    const getCourseTitle = () => {
      if (isB2b && user.role === UserRole.LEARNER) {
        return 'Courses';
      }
      return 'Courses & Pathways';
    };

    const explorePageGroup: ItemGroup = {
      group: 'Explore page',
      items: [
        {
          value: 'Explore / homepage',
          label: 'Explore / homepage',
        },
        {
          value: 'Learning Journey',
          label: 'Learning Journey',
        },
        {
          value: getCourseTitle(),
          label: getCourseTitle(),
        },
        {
          value: 'Techniques',
          label: 'Techniques',
        },
        {
          value: 'Tools',
          label: 'Tools',
        },
        {
          value: 'Books',
          label: 'Books',
        },
      ],
    };

    if (nonEmergnb2bLearner) {
      explorePageGroup.items.splice(0, 2);
    }

    const group = [generalGroup, explorePageGroup] as ItemGroup[];

    const pagesGroup: ItemGroup = {
      group: 'Pages',
      items: [
        {
          label: 'Board',
          value: 'Board',
        },
        {
          label: 'Courses Assessments',
          value: 'Courses Assessments',
        },
        {
          label: 'Courses Interface',
          value: 'Courses Interface',
        },
        {
          label: 'AI Consultant',
          value: 'AI Consultant',
        },
        {
          label: 'Account',
          value: 'Account',
        },
        {
          label: 'Search',
          value: 'Search',
        },
        {
          label: 'Checklist',
          value: 'Checklist',
        },
      ],
    };
    if (nonEmergnb2bLearner) {
      pagesGroup.items.splice(1, 1);
    }

    if (nonEmergnb2bLearner || (!isB2b && user.subscription?.type === UserSubscriptionType.Free)) {
      const indexToRemove = pagesGroup.items.findIndex((i) => i.label === 'AI Consultant');
      pagesGroup.items.splice(indexToRemove, 1);
    }

    if (!userHasAccessTo(AppSections.CHECKLIST)) {
      pagesGroup.items.pop();
    }
    group.push(pagesGroup);

    const praxisManagementGroup: ItemGroup = {
      group: 'Praxis management',
      items: [],
    };

    if (userHasAccessTo(AppSections.ADMIN)) {
      praxisManagementGroup.items.push({
        label: 'Admin',
        value: 'Admin',
      });
    }
    if (userHasAccessTo(AppSections.ANALYTICS)) {
      praxisManagementGroup.items.push({
        label: 'Analytics',
        value: 'Analytics',
      });
    }
    if (userHasAccessTo(AppSections.INSTRUCTOR)) {
      praxisManagementGroup.items.push({
        label: 'Instructor',
        value: 'Instructor',
      });
    }
    if (praxisManagementGroup.items.length > 0) {
      group.push(praxisManagementGroup);
    }

    return group;
  };

  const backToOption = () => {
    closeModal();
    openContactUsModal();
  };

  const handleFormSubmit = async (values: any) => {
    const { section, description } = values;

    const screenResolution = `${screen.width}x${screen.height}`;
    const browserDetails = navigator.userAgent;

    try {
      await contactSupport({ type, section, description, screenResolution, browserDetails });
      openSuccessNotification('', undefined, undefined, textResolver[type].successNotificationTitle);
    } catch {
      openErrorNotification('Please try again.', undefined, undefined, textResolver[type].errorNotificationTitle);
    } finally {
      closeModal();
    }
  };

  const getPreselectedValue = () => {
    const mapper = {
      board: 'Board',
      chatbot: 'AI Consultant',
      'personal-information': 'Account',
      privacy: 'Account',
      checklist: 'Checklist',
      'for-you': 'Explore / homepage',
      'courses-and-pathways': 'Courses & Pathways',
      techniques: 'Techniques',
      tools: 'Tools',
      books: 'Books',
      instructor: 'Instructor',
    };

    for (const [key, value] of Object.entries(mapper)) {
      if (pathname.endsWith(key)) {
        return [value];
      }
    }
    if (pathname.includes('/analytics')) {
      return ['Analytics'];
    }
    if (pathname.includes('/admin')) {
      return ['Admin'];
    }
    if (pathname.includes('/password') || pathname.includes('subscription-and-billing')) {
      return ['Account'];
    }
    if (pathname.includes('learning-journey')) {
      return ['Learning Journey'];
    }
    if (pathname.includes('assessment-quiz')) {
      return ['Courses Assessments'];
    }
    if (pathname.includes('/module')) {
      return ['Courses Interface'];
    }
    return null;
  };

  return (
    <FormWrapper onSubmit={handleFormSubmit}>
      <IdsModal isOpen closeHandler={closeModal} size="md" customClasses="ContactUsModalForm" isFullyScrollable>
        <div slot="header">
          <IdsText component="h3" weight="bold" size="sm">
            <>{textResolver[type].modalHeader}</>
          </IdsText>
        </div>
        <div slot="main">
          <IdsFieldWrapper
            wrapperLabel="Page"
            helperIcon="ui-alert-information_circle"
            helperText={textResolver[type].dropdownHelperText}
            htmlFor="section"
            isRequired
          >
            <IdsDropdown
              idValue="section"
              rest={{ name: 'section' }}
              items={getSections()}
              themeName="vfq"
              initialSelectedItems={getPreselectedValue()}
            />
          </IdsFieldWrapper>
          <IdsFieldWrapper wrapperLabel={textResolver[type].textareaLabel} isRequired htmlFor="description">
            <IdsTextarea
              idValue="description"
              rest={{ name: 'description' }}
              rows={5}
              placeholder={textResolver[type].textareaPlaceholder}
              changeHandler={setDescription}
            />
          </IdsFieldWrapper>
        </div>
        <div slot="footer">
          <IdsButtonGroup fluidWidth={isSmallMobile} spaceBetween="lg" customClasses="ContactUsModalFormButtonGroup">
            <IdsButton clickHandler={backToOption} iconLeft="ui-navigation-arrow_left" variant="tertiary">
              <>{isSmallMobile ? 'Back' : 'Back to options'}</>
            </IdsButton>
            <IdsButton clickHandler={closeModal} variant="secondary">
              Cancel
            </IdsButton>
            <IdsButton
              data-elp-testid="submit-button"
              type="submit"
              fullWidth={isSmallMobile}
              isDisabled={!description || !getPreselectedValue()}
            >
              {textResolver[type].submitButtonTitle}
            </IdsButton>
          </IdsButtonGroup>
        </div>
      </IdsModal>
    </FormWrapper>
  );
};

export default ContactSupportModal;
